<template>
  <div class="flex flex-wrap items-center mt-10 mb-10">
    <vs-button class="mr-6" :disabled="!isValid" @click="$emit('click')">
      Save
    </vs-button>

    <vs-button type="border" color="danger" @click="$emit('cancel')">
      Cancel
    </vs-button>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line
    valid: {},
  },
  computed: {
    isValid() {
      return Boolean(this.valid);
    },
  },
};
</script>
