import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import SidebarButtons from '@/views/sidebars/components/SidebarButtons';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  components: {
    SidebarButtons,
    VuePerfectScrollbar,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    isActive: {
      get() {
        return this.active;
      },
      set(val) {
        if (!val) {
          this.$emit('close');
        }
      },
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    titleType() {
      if (!this.data) {
        return 'ADD';
      }

      return Object.entries(this.data).length === 0 ? 'ADD' : 'UPDATE';
    },
    shouldUpdate() {
      if (!this.data) {
        return false;
      }

      return Boolean(this.dataId) && this.dataId >= 0;
    },
  },
  methods: {
    handleClose() {
      this.isActive = false;
    },
  },
};
